import React, { FunctionComponent } from 'react';
import Alert from '@mui/material/Alert';

const WarningFieldAlert: FunctionComponent<
    { message: string | JSX.Element }
> = ({ message }) => {
    return <Alert
        severity={ 'warning' }
        sx={{ fontWeight: 600, '& .MuiAlert-message': { marginRight: '12px' }}}
    >{ message }</Alert>;
};

export default WarningFieldAlert;
